import React from 'react';
import banner1 from '../img/banner.jpg';
import banner2 from '../img/banner3.png';
import banner3 from '../img/banner4.jpg';
import banner4 from '../img/basic.jpeg';
import banner5 from '../img/banner1.png'
import banner6 from '../img/banner2.jpg'

import newbanner1 from '../images/nbanner1.jpg'
import newbanner2 from '../images/nbanner4.jpg'


const Carousel = () => {
    return (
        <>

            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" style={{ marginTop: '05px' }}>
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
                </div>
                <div className="carousel-inner">

                    <div className="carousel-item active">
                        <img src={newbanner1} className="d-block w-100" alt="Banner 1" />
                    </div>

                    <div className="carousel-item ">
                        <img src={banner1} className="d-block w-100" alt="Banner 1" />
                    </div>

                    <div className="carousel-item ">
                        <img src={newbanner2} className="d-block w-100" alt="Banner 1" />
                    </div>



                    <div className="carousel-item">
                        <img src={banner2} className="d-block w-100" alt="Banner 3" />
                    </div>
                    <div className="carousel-item">
                        <img src={banner3} className="d-block w-100" alt="Banner 4" />
                    </div>


                    <div className="carousel-item">
                        <img src={banner4} className="d-block w-100" alt="Banner 6" />
                    </div>


                    <div className="carousel-item">
                        <img src={banner6} className="d-block w-100" alt="Banner 6" />
                    </div>

                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>



            </div>

        </>
    );
}

export default Carousel;
