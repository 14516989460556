import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Images for services
import checkupImg from '../img/health.jpg';
import pregnancyImg from '../img/hrp.jpg';
import infertilityImg from '../img/infertty2.jpg';
import adolescentImg from '../img/adol.jpg';
import gynoImg from '../img/disorder.jpg';
import familyPlanningImg from '../img/family.jpg';
import cervicalScreeningImg from '../img/cervical.jpg';
import cervicalVaccineImg from '../img/vaccin.jpg';
import pathologyImg from '../img/pathology.jpg';

const ServicesPane = () => {
    const services = [
        {
            name: "Regular Checkups for Pregnant Ladies",
            description: "Conditions like high blood pressure, obesity, diabetes, and infections increase pregnancy risks. Regular checkups are essential.",
            image: checkupImg,
        },
        {
            name: "High Risk Pregnancy Consultation",
            description: "High-risk pregnancies include conditions like diabetes, hypertension, thyroid problems, PCOS, and more.",
            image: pregnancyImg,
        },
        {
            name: "Infertility Consultation",
            description: "Infertility consultation helps couples who face difficulty in conceiving by diagnosing and discussing treatments.",
            image: infertilityImg,
        },
        {
            name: "Adolescent & Menopausal Clinic",
            description: "Specialist clinic offering advice on early menopause, fertility issues, and post-cancer treatment effects.",
            image: adolescentImg,
        },
        {
            name: "Other Gynaecological Disorders Consultation",
            description: "Routine gynecological assessments to detect diseases and infections related to the reproductive system.",
            image: gynoImg,
        },
        {
            name: "Family Planning Services",
            description: "Helps in avoiding unintended pregnancies, reducing STD spread, and managing infertility issues.",
            image: familyPlanningImg,
        },
        {
            name: "Cervical Cancer Screening",
            description: "A pap smear test for detecting cervical and uterine cancers. Testing is recommended every three years starting at age 21.",
            image: cervicalScreeningImg,
        },
        {
            name: "Cervical Cancer Vaccination",
            description: "HPV vaccine is recommended for children starting at age 9 to prevent cervical cancer.",
            image: cervicalVaccineImg,
        },
        {
            name: "Pathology Sample Collection",
            description: "Pathology tests, including blood and tissue samples, help in diagnosing diseases.",
            image: pathologyImg,
        },
    ];

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 400, // Faster slide transition (200ms)
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, // Enables automatic sliding
        autoplaySpeed: 1500, // Automatically slides every 1.5 seconds
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <center><h1>Our Services</h1></center>
            <br />

            <div className="services-pane">
                <Slider {...settings}>
                    {services.map((service, index) => (
                        <div className="service-card" key={index}>
                            <img src={service.image} alt={service.name} className="service-image" />
                            <div className="service-info">
                                <h3>{service.name}</h3>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default ServicesPane;
