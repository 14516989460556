import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.jpg';

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>

        <div className='navigate'>
            <nav className="navbar navbar-expand-lg ">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" >
                        <b>Home</b>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {/* <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to="/clinic" className="nav-link" onClick={handleNavItemClick}>About</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Obstetric Services</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">

                                    <li><Link to="/Preyconsept" className="dropdown-item" onClick={handleNavItemClick}>Pre-Conception Counselling</Link></li>
                                    <li><Link to="/High" className="dropdown-item" onClick={handleNavItemClick}>High Risk Pregnancy</Link></li>
                                    <li><Link to="/Delivery" className="dropdown-item" onClick={handleNavItemClick}>All Types of Delivery</Link></li>
                                    <li><Link to="/Postnatel" className="dropdown-item" onClick={handleNavItemClick}>Post Natal Care of Mother</Link></li>
                                    <li><Link to="/Breast" className="dropdown-item" onClick={handleNavItemClick}>Lactation & Breast Care Clinic</Link></li>
                                    <li><Link to="/Family" className="dropdown-item" onClick={handleNavItemClick}>Family Planning & Contraception Clinic</Link></li>

                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">Gynecological Services</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                    <li><Link to="/Gyneo" className="dropdown-item" onClick={handleNavItemClick}>Gynecological Cancer Screening</Link></li>
                                    <li><Link to="/Menupause" className="dropdown-item" onClick={handleNavItemClick}>Menopause Clinic</Link></li>
                                    <li><Link to="/Managharmon" className="dropdown-item" onClick={handleNavItemClick}>Management of Hormonal Disturbance</Link></li>
                                    <li><Link to="/Abdominal" className="dropdown-item" onClick={handleNavItemClick}>Total Abdominal Hysterectomy </Link></li>
                                    <li><Link to="/Tuboplastic" className="dropdown-item" onClick={handleNavItemClick}>Tuboplasty Micro Surgeries for Fertility Enhancement</Link></li>
                                    <li><Link to="/Turbosurgery" className="dropdown-item" onClick={handleNavItemClick}>Surgery for Turbo-Ovaries Masses</Link></li>
                                    <li><Link to="/Myomactomy" className="dropdown-item" onClick={handleNavItemClick}>Myomectomy (for Fibroid Uterus)</Link></li>
                                </ul>
                            </li>
                          
                           
                            <li className="nav-item">
                                <Link to="/gallery" className="nav-link" onClick={handleNavItemClick}>Gallery</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link" onClick={handleNavItemClick}>Contact</Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/Appointment" className="nav-link" onClick={handleNavItemClick}>Appointment</Link>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </nav>

            </div>
        </>
    );
}

export default Navbar;
