import React from 'react'
import Footer from '../component/Footer'


const Breast = () => {
    return (
        <>
            <div className='heading'>
                <h1>Lactation & Breast Care Clinic</h1>
                <p>Home / Lactation & Breast Care Clinic</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <h1>How would I know that I am suffering from a breast disease?</h1>
                    <p>To know that you are suffering from a breast disease will be indicated by the presence of:</p>
                    <p>The expert gynecologist at our clinic, Dr. Smiti states the possible causes of heavy bleeding during the menstrual cycle may include:</p>

                    <p>Abnormal watery discharge from the nipples.</p>
                    <p> Pain in the breast.</p>
                    <p>Swelling, lumping, and thickening of the breasts.</p>
                    <p>Development of warts near the nipples</p>
                    <p>Milk production despite no breastfeeding</p>

                    <p>No, the breast test like mammography is not painful at all but it can cause mild discomfort. For the mammography test, your breast will be placed between the two plastic plates which then compresses the breast to the point where it can cover maximum tissues.</p>

                </div>




            </div>

            <br />

           <Footer/>
        </>
    )
}

export default Breast