import React from "react";
import set from '../images/nr8.jpg';
import set2 from '../img/vidg1.mp4'


const ImageVideoSection = () => {
    return (
        <div className="image-video-container">
          
            <div className="image-section">
                <img
                    src={set} 
                    alt="Placeholder"
                    className="image"
                />
            </div>

            
            <div className="video-section">
                <video
                    src={set2} 
                    controls
                    autoPlay
                    muted
                    loop
                    className="video"
                />
            </div>
        </div>
    );
};

export default ImageVideoSection;
