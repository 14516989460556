import React from 'react'
import '../component/Head.css';
import just from '../img/justdial.png'

const Head = () => {
    return (
        <>

            <div className='head'>

                <div className='head2'>
                    <div className='social'>
                        <div class="socia2">
                            <a href="https://www.facebook.com/drneerjasinghlko" ><i className="fa fa-facebook-f" style={{backgroundColor:'#5281cc'}} ></i></a>
                            <a href="https://www.instagram.com/dr.neerja.clinic/?igshid=N2ZiY2E3YmU%3D"><i className="fa fa-instagram" style={{backgroundColor:'#0dbeea'}}></i></a>
                            <a href="https://www.youtube.com/channel/UCK4pvR5sC0OtY66_wJ8elig"><i className="fa fa-youtube-play" style={{backgroundColor:'#ff3d30 '}}></i></a>
                            <a href="https://www.google.com/search?q=neerja+clinic+lucknow+uttar+pradesh"><i className="fa fa-google" style={{backgroundColor:' #10b833'}}></i></a>
                            <a href="https://www.justdial.com/Lucknow/Dr-Neerja-Singh-Neerja-Clinic-Gomti-Nagar/0522PX522-X522-170125004223-F9W7_BZDET" style={{ textDecoration:'none',marginLeft:'-15px'}}> <i><img src={just}/></i></a>
                        </div>    
                    </div>
                </div>


                <div className='head3'>

                    <div className='marks'>
                        <marquee>
                            <p>In Service of Comprehensive women health for more than 3 decades</p>

                        </marquee>

                    </div>

                </div>


            </div>




        </>
    )
}

export default Head