import React from 'react';


const Frequent = () => {
  return (

    <div className='frequently'>
      <div className="faq-container">
       
       <h2>Frequently Asked Questions</h2>
       <br />
        <div className="faq-item">
          <h3>1. Can I visit a gynaecologist & obstetrician doctor if I am experiencing irregular periods?</h3>
          <p>Yes, you can visit a gynaecologist & obstetrician when facing irregular periods and other similar problems.</p>
        </div>
        <div className="faq-item">
          <h3>2. Does Dr. Neerja Singh (Neerja Clinic) perform surgeries?</h3>
          <p>Most gynaecologists & obstetricians perform surgeries such as c-sections, fibroid removal, ovarian cyst removal, etc. For more details, you can get in touch with Dr. Neerja Singh (Neerja Clinic).</p>
        </div>
        <div className="faq-item">
          <h3>3. Do gynaecologists & obstetricians deliver babies?</h3>
          <p>Yes, gynaecologists & obstetricians are trained in helping women in labour and delivering babies.</p>
        </div>
        <div className="faq-item">
          <h3>4. What does Dr. Neerja Singh (Neerja Clinic) in Gomti Nagar specialise in?</h3>
          <p>Dr. Neerja Singh (Neerja Clinic) specialises in Gynecologist and Obstetrician, Infertility Specialist, etc.</p>
        </div>
        <div className="faq-item">
          <h3>5. How do I book an appointment with Dr. Neerja Singh (Neerja Clinic)?</h3>
          <p>The working timings of Dr. Neerja Singh (Neerja Clinic) Hours: Wednesday 9 am–1 pm, 4–6 pm
            Thursday 9 am–1 pm, 4–6 pm
            Friday 9 am–1 pm, 4–6 pm
            Saturday 9 am–1 pm
            Sunday 11 am–1 pm
            Monday 9 am–1 pm, 4–6 pm
            Tuesday 9 am–1 pm, 4–6 pm</p>
        </div>
      </div>
    </div>
  )
}

export default Frequent;
