import React from 'react'
import Footer from '../component/Footer'


const Postnatel = () => {
    return (
        <>
            <div className='heading'>
                <h1>Post Natal Care of Mother</h1>
                <p>Home / Post Natal Care of Mother</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <p>Women in the postnatal period need to maintain a balanced diet, just as they did during pregnancy. Iron and folic acid supplementation should also continue for 3 months after birth. Women who are breastfeeding require additional food and should drink sufficient clean water.</p>
                    <p>The care includes prevention, elimination, early detection and cure of health complications (if any), counselling on breastfeeding, immunization, an interactive session on the importance of birth spacing and maternal nutrition, and so on.</p>

                    <p>The major purposes of postpartum and postnatal care are to maintain and promote the health of the woman and her baby and to foster an environment that offers help and support to the extended family and community for a wide range of related health and social needs.</p>
                </div>

            </div>

            <br />

            <Footer/>
        </>
    )
}

export default Postnatel