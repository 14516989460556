import React, { useState } from "react";
import Swal from "sweetalert2";

export default function Appointment() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("access_key", "168a1465-8167-42e7-b825-2722113f3f80");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Message sent successfully!",
        confirmButtonText: "OK",
      }); // Success alert
      event.target.reset();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to send message.",
        confirmButtonText: "Try Again",
      }); // Error alert
    }
  };

  return (
    <div className="reachus-container">
      <div className="reachus-content">
        <h1>Appointment</h1>
        <p>We are here to assist you! Please fill out the form below to contact us.</p>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label>Name:</label>
            <input type="text" name="name" required />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" name="email" required />
          </div>
          <div className="form-group">
            <label>Contact:</label>
            <input type="tel" name="contact" required />
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input type="text" name="address" required />
          </div>
          <div className="form-group">
            <label>Appointment Date:</label>
            <input type="date" name="date" required />
          </div>
          <div className="form-group">
            <label>Appointment Time:</label>
            <input type="time" name="time" required />
          </div>
          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
}
