import React from 'react'
import dr from '../images/nr3.jpg';


const About = () => {
    return (
        <>
            <div className='about'>

                <div className='about1 '>
                    <img src={dr} />
                </div>
                <div className='about2'>
                    <h3>Meet Our Consultant</h3>
                    <h1><b>Dr. Neerja Singh</b></h1>
                    <h1>Obstetrician, Gynecologist,  & Infertility</h1>
                    <h1>M.B.B.S , D.G.O. (P.G.D.H.A. )
                    </h1>
                    <br />
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', lineHeight: '1.5' }}>
                        <b style={{ fontWeight: 'bold' }}>30 + Years</b> Of Experience
                    </p>
                    <p>In Service of Comprehensive women health for more than 3 decades</p>
                    <p>Believes in Providing Safe evidence based, effective, patient centric, timely efficient and equitable health care</p>
                    <p>It believes in Consistency and continuity of care.
                        </p>
                        <p>Health Care For Womens of
                        All Ages For Adolescent To Menopause</p>
                        <p>Infertility Consultant, Menopause Consultant, Adolescent Helth Care ,Family Planing Services (Pills,DMPA,Freedom 5 & Other),Screening For Gynecological Cancers(Cervical & other) , Cervical Cancer Vaccine , Pathology Sample Collection</p>
                    

                    {/* <a href='Tel:7081000958'><button>CALL NOW </button></a> */}

                </div>

            </div>

            <br />
            <hr />







        </>
    )
}

export default About