import React, { useState } from 'react';
import Footer from '../component/Footer';

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [address, setAddress] = useState("");
    const [date, setDate] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        let message = `Booking Details:\n`;
        message += `Name: ${name}\n`;
        message += `Email: ${email}\n`;
        message += `Contact Number: ${contact}\n`;
        message += `Address: ${address}\n`;
        message += `Preferred Date: ${date}\n`;

        const whatsappNumber = "7081000959";
        const whatsappURL = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;

        window.open(whatsappURL, '_blank');

        // Clear form
        setName("");
        setEmail("");
        setContact("");
        setAddress("");
        setDate("");
    };

    return (
        <>
            <div className='heading'>
                <h1>CONTACT US</h1>
                <p>Home / Contact Us </p>
            </div>

            <div className='contat'>
                <div className='contat2'>
                    <div className='content1'>
                        <h2>Contact Us  </h2>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                            
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            
                            <label htmlFor="contact">Contact Number:</label>
                            <input type="tel" id="contact" name="contact" value={contact} onChange={(e) => setContact(e.target.value)} required />
                            
                            <label htmlFor="address">Address:</label>
                            <input type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                            
                           
                            
                       
                            <button type="submit">Submit</button>

                            <br/>

                            <br/>
                        </form>

                        <br />
                    </div>

                    <br />
                </div>

                <div className='contat2'>
                    <h1>Get In Touch</h1>
                    <p>Our team of experts can help answer any questions you might have.
                        Please fill out the form below and a consultant will respond shortly.</p>

                        <p><b>Address</b>  :  Eldeco Green Woods Colony Malhaur Road Gomti Nagar , Chinhat , Lucknow, 226028</p>
                        <p><b>Email </b>  :  clinicneerja@gmail.com</p>
                    <p>+91 7081000959</p>
                    <p>+91 9161328999</p>
                </div>
            </div>

            
            <br/><hr />
            <div className='maps'>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14236.31789366715!2d81.0344333!3d26.869216!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be38e9aeebbe1%3A0xbbccb24f3910de1f!2sDr%20Neerja%20Clinic%20-%20Best%20Gynecologist%20in%20Gomti%20nagar%20lucknow%20%7C%20PCOD%2C%20High%20risk%20Pregnancy%20%26%20Infertility%20Clinic%20In%20Gomti%20Nagar%20%7C!5e0!3m2!1sen!2sin!4v1727533679972!5m2!1sen!2sin" 
                    width="100%" 
                    height="100%"  
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

            <Footer />
        </>
    );
};

export default Contact;
