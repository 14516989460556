import React from 'react'
import Carousal from './Carousal'
import About from './About'
import Treatment from './Treatment'
import Content from './Content'
import Footer from './Footer'
import Frenquent from './Frenquent'
import Sections from './Sections'
import Gynecology from './Gynecology'
import ServicesPane from './ServicesPane'
import ImageVideoSection from './ImageVideoSection'
import Mission from './Mission'
import ReachUs from './ReachUs'

const Home = () => {
  return (

    <div className='main'>

      <Carousal />
   
      <hr/>
      <About />
      <Gynecology/>
      <ImageVideoSection/>
      <ServicesPane/>
      <br/><br/>
      <Mission/>
      <br/>
      <ReachUs/>
      
      <Frenquent />
      
      <Footer />

      
    </div>


  )
}

export default Home