import React from 'react';
import meno from '../img/menopause.jpg'
import high from '../img/high.jpg'
import cancer from '../img/gyneocancer.jpg'
import infertility from '../img/infertility.png'

const Gynecology = () => {
    return (
        <>


            <div className='gyneo'>
                Most Useful  Gyne Health  Consultation
            </div>



            <div className="gynecology-container">
                <div className="section">
                    <img src={meno} alt="Monopausal Consultation" />
                    <div className="hover-content">
                        <h2>Monopausal Consultation</h2>
                    </div>
                </div>

                <div className="section">
                    <img src={high} alt="High Risk Pregnancy" />
                    <div className="hover-content">
                        <h2>High Risk Pregnancy</h2>
                    </div>
                </div>

                <div className="section">
                    <img src={cancer} alt="Cancer Screening" />
                    <div className="hover-content">
                        <h2>Cancer Screening</h2>
                    </div>
                </div>

                <div className="section">
                    <img src={infertility} alt="Infertility Consultant" />
                    <div className="hover-content">
                        <h2>Infertility Consultant</h2>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Gynecology;
