import React from 'react'
import Footer from '../component/Footer'


const Family = () => {
    return (
        <>
            <div className='heading'>
                <h1>Family Planning & Contraception</h1>
                <p>Home / Family Planning & Contraception</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <h1>What is contraception and family planning?</h1>

                    <p>Contraception is a subset of family planning/birth control, defined as the intentional use of artificial methods or other practices to prevent pregnancy. The primary forms of contraception are a barrier method, hormonal, nonhormonal IUD, and sterilization.</p>
                    <h1>What is the best family planning method?</h1>
                    <p>The kinds of birth control that work the best to prevent pregnancy are the implant and IUDs — they're also the most convenient to use, and the most foolproof. Other birth control methods, like the pill, ring, patch, and shot, are also really good at preventing pregnancy if you use them perfectly.</p>


                    <h1>What are types of family planning?</h1>
                    <p>Methods of contraception include oral contraceptive pills, implants, injectables, patches, vaginal rings, intra uterine devices, condoms, male and female sterilization, lactational amenorrhea methods, withdrawal and fertility awareness-based methods</p>

                </div>



              
                
            </div>

            <br />

            <Footer/>
        </>
    )
}

export default Family