import React from "react";

export default function Mission() {
  return (
    <div className="mission-container">
      <div className="mission-content">
        <h1>Our Mission</h1>
        <p>
          Committed to delivering quality care to women and children, providing the best services for a healthy and bright future.
        </p>
        <div className="mission-stats">
          <div className="stat-item">
            <h2>35</h2>
            <p>Years in Serving Women & Children</p>
          </div>
          <div className="stat-item">
            <h2>22000</h2>
            <p>Healthy Babies Delivered</p>
          </div>
          <div className="stat-item">
            <h2>16000</h2>
            <p>New Born Sent Home From NICU</p>
          </div>
          <div className="stat-item">
            <h2>10500</h2>
            <p>Children Sent Home From PICU</p>
          </div>
        </div>
      </div>
    </div>
  );
}
