import React, { useState } from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send form data to a server)
    console.log({ name, email, contact, subject, message });
    // Clear the form
    setName("");
    setEmail("");
    setContact("");
    setSubject("");
    setMessage("");
  };
  return (

    <>

      <div className="whatsapp-btn">
        <a href="https://wa.me/919670777704" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-whatsapp"></i>
        </a>
      </div>

      <div className="call-now-btn">
        <a href="tel:+919670777704">
          <i className="fa fa-phone"></i>
        </a>
      </div>





      <div className='footer'><br />
        <center>
          <h1>If you Have Any Questions Call Us On : +91 9161328999</h1>
        </center>
        <div className='footer1'>

          <div className='foot'>
            <h1>About us</h1>
            <p>We offer healthcare consultation for women
              of all ages right from adolescence to menop
              ause. Facilities provided include OPD, Infert
              ility Consultancy, High Risk Pregnancy cons
              ultancy, Menopausal consulting, Adolescent
              health care, Family Planning, Cervical Canc
              er Screening & Treatment Services.</p>
          </div>
          <div className='foot' style={{ textAlign: 'center' }}>
            <h1>Quick Links</h1><br />
            <Link to='/' className='link'>Home</Link><br />
            <Link to='/clinic' className='link'>About</Link><br />
            <Link to='/gallery' className='link'>Gallery</Link><br />
            <Link to='/contact' className='link'>Contact</Link>
          </div>
          <div className='foot'>
            <h1>Dr. Neerja Singh</h1>
            <p>Obstetrician, Gynecologist, & Infertility
              M.B.B.S , D.G.O. (P.G.D.H.A. ).</p>
            <p><b>Contact </b>: +91 9161328999</p>

          </div>

          <div className='foot'>
            <h1>Contacts</h1>
            <p>+91  9161328999</p>
            <p>+91 7081000959</p>
            <p><b>Address</b>  :  Eldeco Green Woods Colony Malhaur Road Gomti Nagar , Chinhat , Lucknow, 226028</p>
            <p><b>Email </b>  :  clinicneerja@gmail.com</p>
          </div>

        </div>

      </div>

      <div className='disclame'>
        <p>2020-21 ©  Neerja Clinic All Rights Reserved.</p>
      </div>

      <div className="sticky-vertical-button">
        <a href="#contact-form" >CONTACT US </a>
      </div>




      <div id="contact-form" className="popup-form">
        <a href="#" className="close-btn">&times;</a>
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            placeholder="Contact"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>

      <div className="overlay"></div>



      <div className="social-media-vertical">
        <a href="https://www.facebook.com/drneerjasinghlko" target="_blank" rel="noreferrer" className="facebook">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/dr.neerja.clinic/?igshid=N2ZiY2E3YmU%3D" target="_blank" rel="noreferrer" className="instagram">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.youtube.com/channel/UCK4pvR5sC0OtY66_wJ8elig" target="_blank" rel="noreferrer" className="youtube">
          <i className="fab fa-youtube"></i>
        </a>
      </div>

      <a href="https://meet.google.com/new" class="video-vertical-button" target="_blank">
        Online Consultant
      </a>
    </>
  )
}

export default Footer