import React from 'react'
import Footer from '../component/Footer'


const Abdominal = () => {
    return (
        <>
            <div className='heading'>
                <h1>Abdominal Hysterectomy </h1>
                <p>Home / Abdominal Hysterectomy</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>


                    <p><strong>Uterine Fibroids:</strong> Noncancerous growths in the uterus that can cause symptoms such as heavy menstrual bleeding, pelvic pain, and pressure.</p>
                    <p><strong>Endometriosis:</strong> A condition in which the tissue that normally lines the inside of the uterus grows outside the uterus, leading to pain and sometimes infertility.</p>
                    <p><strong>Uterine Prolapse:</strong> When the uterus slips down into or protrudes out of the vagina, often due to weakened pelvic floor muscles.</p>
                    <p><strong>Adenomyosis:</strong> A condition in which the inner lining of the uterus (endometrium) breaks through the muscle wall of the uterus, causing heavy menstrual bleeding and pelvic pain.</p>
                    <p><strong>Cancer:</strong> Hysterectomy may be part of the treatment for uterine, cervical, or ovarian cancer.</p>
                    <h1>Recovery:</h1>
                    <p>Recovery from an abdominal hysterectomy typically takes several weeks. Patients may experience pain, fatigue, and discomfort during the initial recovery period. It's important to follow post-operative instructions provided by the healthcare team, including restrictions on activities such as heavy lifting and strenuous exercise.

                    </p>

                    <h1>Risks and Complications:</h1>
                    <p>As with any surgical procedure, there are risks associated with abdominal hysterectomy, including bleeding, infection, damage to surrounding organs or structures, and adverse reactions to anesthesia. Additionally, removal of the uterus means the patient will no longer be able to become pregnant. Discussing the risks and benefits of the procedure with a healthcare provider is important before making a decision.</p>

                </div>


            </div>

            <br />
            <Footer />
        </>
    )
}

export default Abdominal