import React from 'react'

import gal1 from '../images/nr1.jpg'
import gal2 from '../images/nr2.jpg'
import gal3 from '../images/nr3.jpg'
import gal4 from '../images/nr4.jpg'
import gal5 from '../images/nr5.jpg'
import gal6 from '../images/nr6.jpg'
import gal7 from '../images/nr7.jpg'


import as1 from '../images/nbanner1.jpg'
import as2 from '../images/nbanner4.jpg'
import as3 from '../images/nr8.jpg'
import as4 from '../images/nr10.jpg'

import Footer from '../component/Footer'


const Gallery = () => {
    return (
        <>
            <div className='heading'>
                <h1>GALLERY </h1>
                <p>Home / Gallery </p>
            </div>



            <div className='gallery'>
                <div className='gallery2'>
                    <img src={gal1} />
                </div>
                <div className='gallery2'>
                    <img src={gal5} />
                </div>
                <div className='gallery2'>
                    <img src={gal3} />
                </div>
            </div>

            <div className='gallery'>
                <div className='gallery2'>
                    <img src={gal2} />
                </div>
                <div className='gallery2'>
                    <img src={gal4} />
                </div>
                <div className='gallery2'>
                    <img src={as4} />
                </div>
            </div>


            <div className='gallery'>
                <div className='gallery2'>
                    <img src={as1} />
                </div>
                <div className='gallery2'>
                    <img src={as2} />
                </div>
                <div className='gallery2'>
                <img src={as3} />
                </div>
            </div>


            <div className='gallery'>
                <div className='gallery2'>
                    <img src={gal6} />
                </div>
                <div className='gallery2'>
                    <img src={gal7} />
                </div>
                <div className='gallery2'>
               
                </div>
            </div>


        <Footer/>
        </>
    )
}

export default Gallery

